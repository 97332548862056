<template>
  <article class="phLocation phLocation-placeholder">
    <header class="phLocation_header mt-4 mb-3">
      <div class="phLocation_icon" />
      <div class="phLocation_name" />
    </header>
    <VehiclePlaceholder
      v-for="index in 4"
      :key="index"
      class="mb-3"
    />
  </article>
</template>

<script>
import VehiclePlaceholder from '../VehiclePlaceholder/VehiclePlaceholder';

export default {
  name: 'LocationPlaceholder',
  components: {
    VehiclePlaceholder,
  },
};

</script>
